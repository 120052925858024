<template>
  <div class='w-100 my-3'>
    <p class='hidden' id='linkIndication'>{{this.user.linkIndication}}</p>
    <base-button
      class='mb-3 float-right btn_new_style'
      @click='copyLink()'
    >
      <i
        class='fas fa-copy '
      />
      Copiar link de indicação
    </base-button>

    <div class='w-100 my-2 '>
      <b-button-group class='buttons'>
        <b-button style='border-radius: 8px' :class="component.name === 'ActivePlan' ? 'btn_active':'btn'" @click="changeTabs('ActivePlan')">
         <span>
           Planos Ativos
         </span>
        </b-button>

        <b-button style='border-radius: 8px' v-if='showChangeCardFunctionality()' :class="component.name === 'CreditCard' ? 'btn_active':'btn'"
                  @click="changeTabs('CreditCard')">
          <span>Cartão de Crédito </span>
        </b-button>

        <b-button style='border-radius: 8px' :class="component.name === 'PurchaseHistory' ? 'btn_active':'btn'"
                  @click="changeTabs('PurchaseHistory')">
          <span>Histórico de Compras </span>
        </b-button>
      </b-button-group>
    </div>

    <div class='w-100 components'>
      <component :is='component' />
    </div>
  </div>
</template>

<script>
import CreditCard from '@/components/CreditCard/CreditCard'
import AddCreditCard from '@/components/CreditCard/AddCreditCard'
import ActivePlan from '@/components/ChangePlan/ActivePlan'
import PurchaseHistory from '@/components/ChangePlan/Table'
import { mapActions } from 'vuex'

export default {
  components: {
    CreditCard,
    AddCreditCard,
    ActivePlan,
    PurchaseHistory
  },
  data() {
    return {
      component: 'ActivePlan',
      components: {
        ActivePlan: ActivePlan,
        CreditCard: CreditCard,
        PurchaseHistory: PurchaseHistory
      },
      attempt: false,
      addCreditCard: false,
      paymentMethod: 'bank_slip',
      plan: 'AN1HO',
      password: '',
      message: '',
      plans: {},
      disabled: false
    }
  },
  mounted() {
    this.component = this.components['ActivePlan']
  },
  async created() {
    const breadcrumb = {
      tab: '',
      page: 'Minha conta',
      subPage: {
        name: 'Minha conta',
        active: false
      },
      tabs: [
        {
          name: 'Seu plano',
          active: true
        }
      ]
    }
    await this.setBreadcrumb(breadcrumb)
    const sessionId = localStorage.getItem("sessionId");
    const user = (await this.$api.get(`users/${sessionId}`)).data;
    await this.$store.dispatch('user/updateUser', user);
  },
  filters: {
    addCurrency(value) {
      return `R$ ${parseFloat(value).toLocaleString('pt-BR', {
        minimumFractionDigits: 2
      })}`
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
  },
  methods: {
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    async copyLink() {
      const content = document.getElementById('linkIndication').innerHTML;
      await navigator.clipboard.writeText(this.user.linkIndication)
        .then(() => {
          this.$bvToast.toast('Link de indicação copiado.', {
            title: 'Aviso',
            variant: 'success',
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000
          })
        })
        .catch((err) => {
          this.$bvToast.toast('Não foi possivél copiar o ink de indicação.', {
            title: 'Aviso',
            variant: 'danger',
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000
          })
        })
    },
    changeTabs(tab) {
      this.component = this.components[tab]
    },
    showChangeCardFunctionality() {
      return this.user && this.user.plan && this.user.plan.plan === 'MO1HO' && this.user.plan.method === 'credit_card' && this.user.plan.status === 'paid'
    }
  }
}
</script>
<style scoped>
.buttons {
  width: 100%;
  border: 1px solid #022245;
  border-radius: 8px;
}

.btn {
  background-color: transparent;
  padding: 7px;
  color: #022245;
  border: none;
  outline: none !important;
}

.btn_active {
  text-align: center;
  border: none;
  width: 40%;
  background-color: #022245 !important;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  color: #ECF0F3;
}

.btn_active:active {
  border: none;
}

.components {
  background: #FFFFFF;
  box-shadow: 0 0 2px rgba(14, 31, 53, 0.12);
  border-radius: 5px;
  padding: 8px;
}

.card-header h1 {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: #999;
  padding: 0;
  margin: 0;
}
</style>
