<template>
  <div class='payment'>
    <b-modal
      header-bg-variant="info"
      header-text-variant="light"
      ref='changeCreditCard'
      id='changeCreditCard'
      size='lg'
      no-close-on-esc no-close-on-backdrop
      title='Alterar cartão de crédito'
    >
      <h6>Essa ação vai alterar o cartão de crédito das próximas cobranças da sua assinatura.</h6>
      <h6>Vai ser usado o cartão de crédito que está sendo cadastrando nesse momento.</h6>
      <h6>Deseja realmente fazer essa alteração?</h6>

      <template #modal-footer>
        <div class='d-flex w-100'>
          <base-button
            class='w-50 mr-2'
            danger
            @click="$refs['changeCreditCard'].hide()"
          >
            Cancelar
          </base-button>

          <b-overlay
            :show="loading"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block w-50"
          >
            <base-button
              class='w-100'
              :disabled='loading'
              success
              @click='submit()'
            >
              Sim
            </base-button>
          </b-overlay>
        </div>
      </template>
    </b-modal>

    <b-modal
      header-bg-variant="info"
      header-text-variant="light"
      ref='subscriptionPaymentMethod'
      id="subscriptionPaymentMethod"
      size='lg'
      no-close-on-esc no-close-on-backdrop
      title='Alterar forma de pagamento da assinatura'
    >
      <h6>Essa ação vai alterar a forma de pagamento da sua assinatura para cartão de crédito das próximas cobranças da sua assinatura. </h6>
      <h6>Deseja realmente fazer essa alteração?</h6>

      <template #modal-footer>
        <div class='d-flex w-100'>
          <base-button
            class='w-50 mr-2'
            danger
            @click="$refs['subscriptionPaymentMethod'].hide()"
          >
            Cancelar
          </base-button>

          <b-overlay
            :show="loading"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block w-50"
          >
            <base-button
              class='w-100'
              :disabled='loading'
              success
              @click='changeSubscriptionPaymentMethod()'
            >
              Sim
            </base-button>
          </b-overlay>
        </div>
      </template>
    </b-modal>

    <b-card class='credit_card-form'>
      <base-input-text
        v-model='v$.payment.data.first_name.$model'
        type='text'
        block
        label='Nome impresso no cartão'
        placeholder='Nome impresso no cartão'
        :error='getError(v$.payment.data.first_name)'
      />

      <base-input-text
        v-model='v$.payment.data.last_name.$model'
        type='text'
        block
        class='mt-4'
        label='Sobrenome impresso no cartão'
        placeholder='Sobrenome impresso no cartão'
        :error='getError(v$.payment.data.last_name)'
      />

      <base-input-text
        v-model='v$.payment.data.number.$model'
        type='text'
        label='Número do Cartão'
        class='mt-4'
        placeholder='0000 0000 0000 0000'
        v-mask="'#### #### #### ####'"
        :error='getError(v$.payment.data.number)'
      />

      <div class='validity mt-4'>
        <base-input-select
          v-model='v$.payment.data.month.$model'
          type='text'
          label='Mês'
          placeholder='Mês'
          :options='monthOptions'
        />

        <base-input-select
          v-model='v$.payment.data.year.$model'
          type='text'
          label='Ano'
          placeholder='Ano'
          :options='yearOptions()'
        />

        <base-input-text
          v-model='v$.payment.data.cvv.$model'
          type='text'
          label='Código de Segurança'
          placeholder='000'
          v-mask="['###', '####', '#####']"
        >
          <template #icon-label>
            <base-tooltip>
              <template #body>
                <i class='fa fa-info-circle' />
              </template>
              <template v-slot:message>
                O código de segurança fica impresso no verso do cartão de crédito.
              </template>
            </base-tooltip>
          </template>
        </base-input-text>
        <div class='cc-back'>
          <img src='../../assets/images/cc-icon.png' />
        </div>
      </div>

      <div class='buttons w-100 d-flex justify-content-end mt-4'>
        <b-overlay
          :show="loading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <base-button :disabled='loading || !canNext()' success @click='handleSubmitPaymentMethod' class='w-100'>
            <img src='@/assets/images/addCard.svg' class='mr-1' />
            <span class='ml-2'>Cadastrar cartão</span>
          </base-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@/utils/validators'
import { getYear } from 'date-fns'
import { mask } from 'vue-the-mask'
import IUGU from '@/services/iugu'

export default {
  name: 'AddCreditCard',
  components: {},
  directives: { mask },
  props:{
    dataToChangeSubscriptionPaymentMethod: { type: Object, default: null },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  computed: {
    ...mapGetters('creditCard', {
      getCreditCards: 'getCreditCards'
    }),
    ...mapGetters('checkout2', {
      payment: 'getPayment'
    })
  },
  data() {
    return {
      token: '',
      loading: false,
      monthOptions: [
        { _id: '1', value: '1 - Janeiro' },
        { _id: '2', value: '2 - Fevereiro' },
        { _id: '3', value: '3 - Março' },
        { _id: '4', value: '4 - Abril' },
        { _id: '5', value: '5 - Maio' },
        { _id: '6', value: '6 - Junho' },
        { _id: '7', value: '7 - Julho' },
        { _id: '8', value: '8 - Agosto' },
        { _id: '9', value: '9 - Setembro' },
        { _id: '10', value: '10 - Outubro' },
        { _id: '11', value: '11 - Novembro' },
        { _id: '12', value: '12 - Dezembro' }
      ]
    }
  },
  created() {
    const breadcrumb = {
      tab: '',
      page: 'Minha conta',
      subPage: {
        name: 'Cartão de Crédito',
        active: false
      },
      tabs: [
        {
          name: 'Alterar cartão de crédito da assinatura',
          active: true
        }
      ]
    }
    this.setBreadcrumb(breadcrumb)
  },
  async mounted() {
    IUGU.setup()
    this.payment.method = 'credit_card'
  },
  validations() {
    return {
      payment: {
        method: { required },
        data: {
          first_name: { required },
          last_name: { required },
          number: { required, minLength: minLength(17) },
          month: { required },
          year: { required },
          cvv: { required, minLength: minLength(3), maxLength: maxLength(5) },
          installments: {}
        }
      }
    }
  },
  methods: {
    ...mapActions('creditCard', {
      handleCreatePaymentMethods: 'handleCreatePaymentMethods',
    }),
    ...mapActions('checkout2', {
      handleSetPromotion: 'handleSetPromotion',
      handleSetPayment: 'handleSetPayment',
      handleGetTokenCreditCard: 'handleGetTokenCreditCard',
      handleSetAlertDetails: 'handleSetAlertDetails',
      handleResetAlertDetails: 'handleResetAlertDetails',
      handleChangeSubscriptionPaymentMethod: 'handleChangeSubscriptionPaymentMethod',
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),

    async handleSubmitPaymentMethod() {
      try {
        this.loading = true
        const response = await this.handleGetTokenCreditCard()
        if (response.error || response.token.error) {
          const message = response.error || response.token.error
          this.$bvToast.toast(message, {
            title: 'Aviso',
            variant: 'danger',
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000
          })
          return
        }
        this.token = response.token
        console.log(this.dataToChangeSubscriptionPaymentMethod)
        const modalRefs = this.dataToChangeSubscriptionPaymentMethod ? 'subscriptionPaymentMethod' : 'changeCreditCard'
        this.$refs[modalRefs].show()

      } finally {
        this.loading = false
      }
    },

    async submit() {
      try {
        this.loading = true
        const { sucess, errors } = await this.handleCreatePaymentMethods({ tokenCard: this.token })
        if (errors) {
          this.$bvToast.toast(errors, {
            title: 'Aviso',
            variant: 'danger',
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000
          })
          return
        }

        this.$bvToast.toast('Alteração efetuada com sucesso!', {
          title: 'Aviso',
          variant: 'success',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.$refs['changeCreditCard'].hide()
        setTimeout(() => {
          this.$emit('close')
        }, 2000)
        const sessionId = localStorage.getItem("sessionId");
        const user = (await this.$api.get(`users/${sessionId}`)).data;
        await this.$store.dispatch('user/updateUser', user);
      } catch (error) {
      } finally {
        this.loading = false
      }
    },

    async changeSubscriptionPaymentMethod() {
      try {
        this.loading = true
        const { data, status } = await this.handleChangeSubscriptionPaymentMethod(this.dataToChangeSubscriptionPaymentMethod)
        if(status === 200){
         await this.submit()
          return
        }

        this.$bvToast.toast('Não foi possível alterar a forma de pagamento.', {
          title: 'Aviso',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
      } catch (error) {
      } finally {
        this.loading = false
      }
    },

    canNext() {
      return !this.v$.payment.data.$invalid
    },

    yearOptions() {
      const now = new Date()
      const currentYear = getYear(now)
      const yearOptions = []
      const CURRENT_YEAR_ADD_15_YEARS = currentYear + 15

      for (let year = currentYear; year <= CURRENT_YEAR_ADD_15_YEARS; year += 1) {
        yearOptions.push({
          _id: year,
          value: year
        })
      }
      return yearOptions
    },

    getError(value) {
      if (value.$errors.length > 0) {
        return value.$errors[0].$message
      }
      return ''
    },
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.payment {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .credit_card-form {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .group, .validity {
      display: grid;
      gap: 16px;
    }

    .group {
      grid-template-columns: 1fr 1fr;
      @media only screen and (max-width: 720px) {
        grid-template-columns: 1fr;
      }
    }

    .validity {
      grid-template-columns: 1fr 1fr 1fr 1fr;

      .cc-back {
        display: flex;
        align-items: flex-end;
        // justify-content: center;
        padding: 5px;

        img {
          width: 40px;
          height: auto;
        }
      }
    }
  }

  .promotion {
    a {
      color: var(--blue);

      &:hover {
        text-decoration: underline;
      }
    }

    .check-code {
      display: flex;
      align-items: flex-end;
      gap: 8px;
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    gap: 5px;
  }
}
</style>
