<template>
  <div>
    <div class="d-flex justify-content-between mb-2" v-if='!addCreditCard'>
      <h5 class='ml-1 card_create'>Seus cartões de crédito cadastrados</h5>
      <base-button
        class='btn-green'
        @click='addCreditCard = true'
      >
        <img src='@/assets/images/updateCard.svg' class='fa fa-download' />
        ALTERAR CARTÃO DA ASSINATURA
      </base-button>
    </div>

    <div v-if='addCreditCard'>
      <div class='d-flex align-items-center justify-content-start my-1'>
        <img @click='addCreditCard = false' src='@/assets/images/return.svg' style='cursor:pointer' class='mb-2 mr-1' />
        <h5 class='ml-1'>Cadastrar cartão de crédito</h5>
      </div>
      <add-credit-card @close="addCreditCard = false" />
    </div>

    <div v-else>
      <div
        v-for='(card, i) in getCreditCards.payment_methods'
        :key='i'
        class="cards"
        :style="getCreditCards.default_payment_method_id === card.id ? 'padding-left: 0': ''"
      >
        <div class='d-flex align-items-center radio-item w-100'
             :class="getCreditCards.default_payment_method_id === card.id ? 'seleciconado': ''"
        >
          <div class='card'>
            <div class='ml-2 pt-2'>
              <svg width='25' height='25' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect width='20' height='20' rx='3' fill='#FDD8AE' />
              </svg>
            </div>

            <div class='mr-2 d-flex justify-content-end brand'>
              <img height='60' width='60' alt='Mastercard'
                   v-bind:src="require('../../assets/images/brand-cards/'+card.data.brand+'.svg')" />
            </div>
          </div>
          <div class='mx-5 '>
            <h4>(Crédito) {{ card.data.brand }}</h4>
            <span v-if='card.data.last_digits'>
              Terminando em: {{ card.data.last_digits }}
            </span>
            <span v-else-if='card.data.display_number'>
               Terminando em: {{ card.data.display_number.toString().substring(card.data.display_number.toString().length - 4)}}
            </span>
          </div>
          <div class='mx-5 '>
            <span>Nome no cartão</span> <br />
            <span>{{ card.data.holder_name }}</span>
          </div>

          <div>
            <span>Vencimento</span> <br />
            <span>{{ card.data.month }}/{{ card.data.year }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AddCreditCard from '@/components/CreditCard/AddCreditCard'
export default {
  name: 'CreditCard',
  components: {
    AddCreditCard
  },
  computed: {
    ...mapGetters('creditCard', {
      getCreditCards: 'getCreditCards'
    })
  },
  data() {
    return {
      loading: false,
      addCreditCard: false,
    }
  },
  created() {
    this.breadcrumb()
  },
  async mounted() {
    await this.handleCreditCards()
  },
  methods: {
    ...mapActions('creditCard', {
      handleGetCreditCards: 'handleGetCreditCards',
      handleChangePaymentMethodSubscription: 'handleChangePaymentMethodSubscription'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    async handleCreditCards() {
      await this.handleGetCreditCards()
    },
    breadcrumb(){
      const breadcrumb = {
        tab: '',
        page: 'Minha conta',
        subPage: {
          name: 'Cartão de Crédito',
          active: false
        },
        tabs: [
          {
            name: 'Meus cartões de crédito',
            active: true
          }
        ]
      }
      this.setBreadcrumb(breadcrumb)
    }
  },
  watch: {
    addCreditCard(){
      this.breadcrumb()
    }
  }
}
</script>

<style lang='scss' scoped>
.card_create{
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #262626;
}
.cards {
  .card {
    width: 180px;
    height: 100px;
    background: #4C63D2;
    border-radius: 10px;

  }
  padding: 20px 20px;
  border-bottom: 1px solid #D9D9D9;
  .brand {
  }

}

.seleciconado {
  width: 100%;
  background: rgba(4, 168, 243, 0.4);
  box-shadow: 0px 7px 20px rgba(45, 48, 56, 0.1);
  border-radius: 10px;
  border-bottom: 1px solid #D9D9D9;
  padding: 20px ;
}
.btn-green {
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 24px;
}
.btn-green i {
  color: #022245;
  margin-right: 6px;
}

.btn-green img {
  width: 15px;
  height: 15px;
  margin-right: 6px;
}
</style>
