<template>
  <div class='surveys-table'>
    <base-table
      v-if='userPlans.length > 0 && userPlansMeta'
      :columns='columns'
      :data='userPlans'
      :total-items='userPlansMeta.total'
      :start-items='userPlansMeta.skip + 1'
      :max-items='userPlansMeta.limit'
      @set-page="changePlans"
    >
      <template v-slot:body-plan='row'>
        {{ row.item.plan ? planName(row.item.plan) : '-' }}
      </template>

      <template v-slot:body-method='row'>
        {{ row.item.method ? planMethod(row.item.method) : '-' }}
      </template>

      <template v-slot:body-status='row'>
        {{ row.item.status ? planStatus(row.item.status) : '-' }}
      </template>

      <template v-slot:body-dueDate="row">
        {{ row.item.dueDate ? formatDate(row.item.dueDate) : '-' }}
      </template>

      <template v-slot:body-createdAt="row">
        {{ row.item.createdAt ? formatDate(row.item.createdAt) : '-' }}
      </template>
    </base-table>
    <span v-else>Usuário não tem planos!</span>
  </div>
</template>

<script>
import format from 'date-fns/esm/format/index'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PurchaseHistory',
  props: {},
  async mounted() {
    await this.handleGetUserPlans({
      userId: this.user.id,
      sortBy: 'createdAt',
      sortIn: 'desc'
    })
  },
  created() {
    const breadcrumb = {
      tab: '',
      page: 'Minha conta',
      subPage: {
        name: 'Cartão de Crédito',
        active: false
      },
      tabs: [
        {
          name: 'Histórico de Compras',
          active: true
        }
      ]
    }
    this.setBreadcrumb(breadcrumb)
  },
  data() {
    return {
      logUpdate: {},
      openDialog: false,
      columns: [
        { key: 'plan', value: 'Plano' },
        { key: 'status', value: 'Status' },
        { key: 'method', value: 'Meio de pagamento' },
        { key: 'createdAt', value: 'Criado em' },
        { key: 'dueDate', value: 'Data de vencimento' }
      ]
    }
  },
  computed: {
    ...mapGetters('userPlans', {
      userPlans: 'userPlans',
      userPlansMeta: 'userPlansMeta'
    }),
    user() {
      return this.$store.getters['user/current']
    }
  },
  components: {},
  methods: {
    ...mapActions('userPlans', {
      handleGetUserPlans: 'handleGetUserPlans'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    planName(plan){
      switch (plan) {
        case 'MO1HO':
          return 'Mensal'
        case 'TRIAL':
          return 'Teste grátis'
        case 'AN1HO':
        case 'PROMO':
          return 'Anual'
        default:
          return 'Outro'
      }
    },
    planMethod(method){
      switch (method) {
        case 'credit_card':
          return 'Cartão de crédito'
        case 'bank_slip':
          return 'Boleto bancário'
        default:
          return '-'
      }
    },
    planStatus(status){
      switch (status) {
        case 'paid':
          return 'Pago'
        case 'pending':
          return 'Pedente'
        case 'expired':
          return 'Expirado'
        case 'canceled':
          return 'Cancelado'
        case 'refunded':
          return 'Reemboldado'
        default:
          return '-'
      }
    },
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },
    async changePlans(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        ...this.userPlansMeta,
        userId: this.user.id,
        skip: (pageCount - 1) * this.userPlansMeta.limit,
        sortBy: 'createdAt',
        sortIn: 'desc'
      }

      await this.handleGetUserPlans(params)
    }
  }
}
</script>

<style lang='scss' scoped>
.api-key {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.whatTheSearchReturned {
  text-align: justify
}

.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;

  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}
</style>
